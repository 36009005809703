import {
  Accordion,
  AccordionItem,
  Button,
  cn,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Slider,
  Spinner,
  Switch,
  useDisclosure
} from '@nextui-org/react'
import { useEffect, useRef, useState } from 'react'
import detail from '../../../assets/button-detail.svg'
import destination from '../../../assets/destination.svg'
import arrowUp from '../../../assets/arrowup.svg'
import arrowDown from '../../../assets/arrowdown.svg'
import arrowLeft from '../../../assets/arrow-left.svg'
import clock from '../../../assets/clock.svg'
import zipIcon from '../../../assets/zip.svg'
import border from '../../../assets/border.svg'
import box from '../../../assets/box.svg'
import union from '../../../assets/date.svg'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.module.css'
import L from 'leaflet'
import 'leaflet-rotatedmarker'
import 'leaflet-geometryutil'
import {
  parserDateToString,
  sendDataUpdate,
  useTelegram
} from '../../../fns/useTelegram'
import { BarLoader } from 'react-spinners'
import _ from 'lodash'
import { renderToString } from 'react-dom/server'
import 'chart.js/auto'
import { Bar } from 'react-chartjs-2'
import ReactDOM from 'react-dom'

function getColorFromQuantity(quantity, minQuantity, maxQuantity) {
  if (quantity === 0 || maxQuantity - minQuantity === 0) {
    return '#B2B2B2'
  }

  // Calculate percentage
  const percentage =
    ((quantity - minQuantity) / (maxQuantity - minQuantity)) * 100

  // Determine color range and calculate ratio
  let color1, color2, rangeMin, rangeMax

  if (percentage < 29) {
    color1 = '#2088FF'
    color2 = '#89BAF4'
    rangeMin = 0
    rangeMax = 29
  } else if (percentage < 49) {
    color1 = '#89BAF4'
    color2 = '#BDDCEF'
    rangeMin = 29
    rangeMax = 49
  } else if (percentage < 73) {
    color1 = '#BDDCEF'
    color2 = '#FFBDB9'
    rangeMin = 49
    rangeMax = 73
  } else {
    color1 = '#FFBDB9'
    color2 = '#F56B6A'
    rangeMin = 73
    rangeMax = 98
  }

  // Calculate ratio within the specific range
  const ratio = (percentage - rangeMin) / (rangeMax - rangeMin)

  // Interpolate color
  return interpolateColor(color1, color2, ratio)
}

function interpolateColor(color1, color2, ratio) {
  let r1 = parseInt(color1.substring(1, 3), 16)
  let g1 = parseInt(color1.substring(3, 5), 16)
  let b1 = parseInt(color1.substring(5, 7), 16)

  let r2 = parseInt(color2.substring(1, 3), 16)
  let g2 = parseInt(color2.substring(3, 5), 16)
  let b2 = parseInt(color2.substring(5, 7), 16)

  let r = Math.round(r1 + (r2 - r1) * ratio)
  let g = Math.round(g1 + (g2 - g1) * ratio)
  let b = Math.round(b1 + (b2 - b1) * ratio)

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
}

export const formatDate = (date) => {
  const options = { day: 'numeric', month: 'short', year: 'numeric' }

  const dateString = `${date.toLocaleDateString('en-US', options)}`

  return dateString
}

export const formatTime = (date) => {
  const options = { hour: 'numeric', minute: 'numeric', hour12: true }

  const timeString = date.toLocaleTimeString('en-US', options)

  return timeString
}

const calculateDaysDifference = (date) => {
  const currentDate = new Date()
  const targetDate = new Date(date)
  const differenceInMilliseconds = targetDate - currentDate

  // Convert milliseconds to days
  const millisecondsInADay = 24 * 60 * 60 * 1000
  const daysDifference = Math.floor(
    differenceInMilliseconds / millisecondsInADay
  )

  return daysDifference
}

export default function Prepare({
  selectedTruck,
  setActivePage,
  states,
  setSelectedTruckData,
  selectedTruckData
}) {
  const { tg } = useTelegram()

  const [truckData, setTruckData] = useState(selectedTruckData)

  const handleLoadTruckData = () => {
    const headers = new Headers()

    headers.append('accept', 'application/json')
    if (process.env.REACT_APP_ENV === 'production') {
      headers.append('telegramid', tg?.initDataUnsafe?.user?.id)
    }

    fetch(
      `${process.env.REACT_APP_HOST}/api/webapp/trucks/details/${selectedTruck.id}`,
      {
        method: 'GET',
        headers: headers
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setTruckData(data)
      })
      .catch((e) => {
        console.log('Error when load truck data:', e)
      })
  }

  useEffect(() => {
    !truckData && handleLoadTruckData()
  }, [])

  if (truckData === null) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999999,
          top: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)'
        }}
      >
        <BarLoader color="#3290EC" size={20} />
      </div>
    )
  }

  return (
    <PreparePage
      truckData={truckData}
      setActivePage={setActivePage}
      defaultStates={states}
      setSelectedTruckData={setSelectedTruckData}
    />
  )
}

const PreparePage = ({
  truckData,
  setActivePage,
  defaultStates,
  setSelectedTruckData
}) => {
  const { tg } = useTelegram()

  const mapContainer = useRef()
  const mapRef = useRef()
  const radarRef = useRef()
  const labelRef = useRef()
  const markerRef = useRef()
  const containerRef = useRef()
  const datepickerRef = useRef()
  const refSelectedAll = useRef()
  const maxRef = useRef()
  const minRef = useRef()
  const zipRef = useRef()
  const oldZipCodeAndDate = useRef({
    zipCode: truckData.zip,
    date: truckData.loc_from
  })
  const zipInputRef = useRef()

  const {
    isOpen: isOpenConfirm,
    onOpen: onOpenConfirm,
    onOpenChange: onOpenChangeConfirm
  } = useDisclosure()

  const {
    isOpen: isOpenUpdateZip,
    onOpen: onOpenUpdateZip,
    onClose: onCloseUpdateZip
  } = useDisclosure()

  const {
    isOpen: isOpenInputZip,
    onOpen: onOpenInputZip,
    onOpenChange: onOpenChangeInputZip
  } = useDisclosure()

  const isFirstRender = useRef({
    zipCode: true,
    outMiles: true
  })

  const [outMiles, setOutMiles] = useState(truckData?.truck?.radius)
  const [date, setDate] = useState(new Date(truckData.loc_from))
  const [tmpDate, setTmpDate] = useState(new Date())
  const [selectStates, setSelectStates] = useState([])
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [zipCode, setZipCode] = useState(truckData?.zip || '')
  const [tmpZipCode, setTmpZipCode] = useState(truckData?.zip || '')
  const [stateOpen, setStateOpen] = useState(false)
  const [totalMilesOpen, setTotalMilesOpen] = useState(false)
  const [location, setLocation] = useState(
    truckData?.lat && truckData?.lng ? [truckData.lat, truckData.lng] : null
  )
  const [timeZone, setTimeZone] = useState(truckData.time_zone)
  const [postOfficeCity, setPostOfficeCity] = useState('')
  const [isSelected, setIsSelected] = useState(false)
  const [states, setStates] = useState(defaultStates)
  const {
    isOpen: isOpenUpdateZipConfirm,
    onOpen: onOpenUpdateZipConfirm,
    onClose: onCloseUpdateZipConfirm
  } = useDisclosure()
  const [step, setStep] = useState(0)
  const [openFrom, setOpenFrom] = useState(0)
  const [loadingZip, setLoadingZip] = useState(false)
  const [isEditingZip, setIsEditingZip] = useState(false)
  const [errorZip, setErrorZip] = useState(false)

  const handleUpdateLocationWhenZipcodeChanged = (zipCode) => {
    const headers = new Headers()

    headers.append('accept', 'application/json')
    if (process.env.REACT_APP_ENV === 'production') {
      headers.append('telegramid', tg?.initDataUnsafe?.user?.id)
    }

    fetch(`${process.env.REACT_APP_HOST}/webapp/zip/${zipCode}`, {
      method: 'GET',
      headers: headers
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.lat && data?.lng) {
          setTimeZone(data.timezone)
          setLocation([data.lat, data.lng])
          setPostOfficeCity(data.post_office_city)
        } else {
          setPostOfficeCity('')
        }
      })
      .catch((e) => {
        console.log('Error when get location:', e)
        setPostOfficeCity('')
        setTimeZone('')
      })
  }

  const handleUpdateZipData = async (zipCodeChange) => {
    const headers = new Headers()

    setLoadingZip(true)

    headers.append('accept', 'application/json')
    if (process.env.REACT_APP_ENV === 'production') {
      headers.append('telegramid', tg?.initDataUnsafe?.user?.id)
    }

    await fetch(
      `${process.env.REACT_APP_HOST}/webapp/zip/pickup_zip/${zipCodeChange}/${truckData.truck.truck_type_id}/${outMiles}`,
      {
        method: 'GET',
        headers: headers
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          setErrorZip(data.message)
          setLoadingZip(false)
          return
        }

        const points = []

        const curZip = data.cur_zip
        const suggestZip = data.suggested_zips

        if (curZip.loc) {
          radarRef.current?.remove && radarRef.current?.remove()

          markerRef.current?.remove && markerRef.current?.remove()

          // labelRef.current?.remove && labelRef.current?.remove()

          const location = [curZip.loc.lng, curZip.loc.lat]

          points.push(location)

          const dataValue = Object.entries(curZip.stat).map(
            ([key, value]) => value
          )

          const min = Math.min(...dataValue)
          const max = Math.max(...dataValue)

          const radarColor = '#55bf3b'

          const dIcon = L.icon({
            iconUrl: destination,
            iconSize: [50, 50],
            popupAnchor: [0, -25],
            shadowSize: [68, 55],
            shadowAnchor: [22, 55]
          })

          const marker = L.marker(location, {
            alt: '',
            icon: dIcon
          }).addTo(mapRef.current)

          markerRef.current = marker

          const radar = L.circle(marker.getLatLng(), {
            radius: outMiles * 1609.34,
            className: 'zoom',
            stroke: '#55bf3b'
          }).addTo(mapRef.current)

          radarRef.current = radar

          radarRef.current.setStyle({
            color: radarColor
          })

          // const radiusInMeters = outMiles * 1609.34

          // // Calculate label position (simplified for demonstration)
          // // This is a naive approach and might not work perfectly for all zoom levels or map projections
          // const labelLatLng = L.GeometryUtil.destination(
          //   radarRef.current.getLatLng(),
          //   45,
          //   radiusInMeters,
          //   mapRef.current
          // )

          // const labelContent = `<div style="background-color: ${radarColor}; padding: 2px 5px; border-radius: 3px; color: #FFFFFF; text-align: center;" >${outMiles} mi</div>`
          // const labelIcon = L.divIcon({
          //   className: 'custom-label', // custom class for CSS styling
          //   html: labelContent,
          //   iconSize: [60, 20]
          // })

          // labelRef.current = L.marker(labelLatLng, { icon: labelIcon }).addTo(
          //   mapRef.current
          // )

          const chartData = {
            labels: Object.entries(curZip.stat).map(([key, value]) =>
              key.split(' ').pop()
            ),
            datasets: [
              {
                data: dataValue,
                backgroundColor: dataValue.map((value) =>
                  getColorFromQuantity(value, min, max)
                ),
                borderWidth: 0
              }
            ]
          }

          const container = document.createElement('div')

          ReactDOM.render(
            <div key={Math.random()}>
              <div className="text-xs font-medium">
                Last Week:{' '}
                <span className="font-bold">
                  {dataValue.reduce((acc, curr) => {
                    return acc + curr
                  }, 0)}{' '}
                  Loads
                </span>
              </div>
              {!dataValue.every((item) => item === 0) && (
                <Bar
                  data={chartData}
                  className="!w-[300px] !h-[150px]"
                  options={{
                    plugins: {
                      legend: {
                        display: false
                      }
                    },
                    scales: {
                      x: {
                        grid: {
                          display: false // Disable x-axis grid lines
                        },
                        ticks: {
                          maxRotation: 0, // Disable label rotation
                          minRotation: 0, // Disable label rotation
                          font: {
                            family: 'Inter-Regular',
                            weight: '500',
                            size: 10
                          }
                        }
                      },
                      y: {
                        grid: {
                          display: false // Disable y-axis grid lines
                        }
                      }
                    },
                    layout: {
                      padding: {
                        left: 10,
                        right: 10
                      }
                    },
                    width: 300,
                    height: 150
                  }}
                />
              )}

              <div className="text-xs font-medium">
                Zip:{' '}
                <span className="font-bold">
                  {curZip.zip}, {curZip.city || ''}
                </span>
              </div>

              <Button
                color="primary"
                className="mt-3 w-full rounded-md"
                size="sm"
                onClick={() => {
                  oldZipCodeAndDate.current = {
                    zipCode: zipCode,
                    date: date,
                    postOfficeCity: postOfficeCity
                  }
                  setZipCode(curZip.zip)
                  setPostOfficeCity(curZip.city || '')
                  onOpenUpdateZip()

                  const tmpDate = new Date()

                  tmpDate.setHours(tmpDate.getHours() + 1)

                  setTmpDate(tmpDate)
                }}
              >
                <div className="text-white text-lg">Select</div>
              </Button>
            </div>,
            container
          )

          marker.bindPopup(container)

          marker.on('click', function (e) {
            // Fly to the marker's location
            mapRef.current.flyTo(location, mapRef.current.getZoom(), {
              animate: true,
              duration: 0.2
            })

            // Pan the map to ensure the location is at the bottom
            setTimeout(() => {
              const mapHeight = mapRef.current.getSize().y
              mapRef.current.panBy([0, -(mapHeight / 2 - 150) / 2], {
                animate: true,
                duration: 0.2
              })
            }, 200) // Delay to ensure flyTo animation completes
          })
        }

        if (suggestZip?.length != 0) {
          suggestZip.map((dataZip) => {
            const dataValue = Object.entries(dataZip.stat).map(
              ([key, value]) => value
            )

            const location = [dataZip.loc.lng, dataZip.loc.lat]

            points.push(location)

            const min = Math.min(...dataValue)
            const max = Math.max(...dataValue)

            const radarColor = '#f56b6a'

            const boxIcon = L.divIcon({
              iconSize: [50, 50],
              popupAnchor: [0, -25],
              shadowSize: [68, 55],
              shadowAnchor: [22, 55],
              html: renderToString(
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 4
                  }}
                >
                  <img
                    src={border}
                    style={{
                      width: `45px`,
                      height: `45px`
                    }}
                  />
                  <img
                    src={box}
                    className="absolute"
                    style={{
                      backgroundColor: radarColor,
                      borderRadius: '100%',
                      width: `35px`,
                      height: `35px`,
                      padding: `4px`
                    }}
                  />
                </div>
              ),
              className: '' // Add any custom class if needed
            })

            const marker = L.marker(location, {
              alt: '',
              icon: boxIcon
            }).addTo(mapRef.current)

            const chartData = {
              labels: Object.entries(dataZip.stat).map(([key, value]) =>
                key.split(' ').pop()
              ),
              datasets: [
                {
                  data: dataValue,
                  backgroundColor: dataValue.map((value) =>
                    getColorFromQuantity(value, min, max)
                  ),
                  borderWidth: 0
                }
              ]
            }

            const container = document.createElement('div')

            ReactDOM.render(
              <div key={Math.random()}>
                <div className="text-xs font-medium">
                  Last Week:{' '}
                  <span className="font-bold">
                    {dataValue.reduce((acc, curr) => {
                      return acc + curr
                    }, 0)}{' '}
                    Loads
                  </span>
                </div>
                <Bar
                  data={chartData}
                  className="!w-[300px] !h-[150px]"
                  options={{
                    plugins: {
                      legend: {
                        display: false
                      }
                    },
                    scales: {
                      x: {
                        grid: {
                          display: false // Disable x-axis grid lines
                        },
                        ticks: {
                          maxRotation: 0, // Disable label rotation
                          minRotation: 0, // Disable label rotation
                          font: {
                            family: 'Inter-Regular',
                            weight: '500',
                            size: 10
                          }
                        }
                      },
                      y: {
                        grid: {
                          display: false // Disable y-axis grid lines
                        }
                      }
                    },
                    layout: {
                      padding: {
                        left: 10,
                        right: 10
                      }
                    },
                    width: 300,
                    height: 150
                  }}
                />
                <div className="w-full flex justify-between">
                  <div className="text-xs font-medium">
                    Zip:{' '}
                    <span className="font-bold">
                      {dataZip.zip}, {dataZip.city}
                    </span>
                  </div>
                  <div className="text-xs font-medium">
                    Dist: <span className="font-bold">{dataZip.dist}mi</span>
                  </div>
                </div>

                <Button
                  color="primary"
                  className="mt-3 w-full rounded-md"
                  size="sm"
                  onClick={() => {
                    oldZipCodeAndDate.current = {
                      zipCode: zipCode,
                      date: date,
                      postOfficeCity: postOfficeCity
                    }
                    setZipCode(dataZip.zip)
                    setPostOfficeCity(dataZip.city || '')
                    onOpenUpdateZip()

                    const tmpDate = new Date()

                    tmpDate.setHours(tmpDate.getHours() + 1)

                    setTmpDate(tmpDate)
                  }}
                >
                  <div className="text-white text-lg">Select</div>
                </Button>
              </div>,
              container
            )

            marker.bindPopup(container).bindTooltip

            marker.on('click', function (e) {
              // Fly to the marker's location
              mapRef.current.flyTo(location, mapRef.current.getZoom(), {
                animate: true,
                duration: 0.2
              })

              // Pan the map to ensure the location is at the bottom
              setTimeout(() => {
                const mapHeight = mapRef.current.getSize().y
                mapRef.current.panBy([0, -(mapHeight / 2 - 150 / 2) / 2], {
                  animate: true,
                  duration: 0.2
                })
              }, 200) // Delay to ensure flyTo animation completes
            })
          })
        }

        const bounds = L.latLngBounds(points)
        mapRef.current.fitBounds(bounds)
      })
      .catch((e) => {
        console.log('Error when get location:', e)
        setErrorZip('Invalid Zip Code')
      })

    setLoadingZip(false)
  }

  const handleLoadStates = () => {
    const headers = new Headers()

    headers.append('accept', 'application/json')
    if (process.env.REACT_APP_ENV === 'production') {
      headers.append('telegramid', tg?.initDataUnsafe?.user?.id)
    }

    fetch(
      `${process.env.REACT_APP_HOST}/api/webapp/directions/${truckData.truck.id}`,
      {
        method: 'GET',
        headers: headers
      }
    )
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        if (data.states) {
          setSelectStates(
            data.states.split(',').map((item) => parseInt(item, 10))
          )

          if (
            data.states.split(',').map((item) => parseInt(item, 10))?.length ===
            states.length
          ) {
            setIsSelected(true)
          }
        }
      })
      .catch((e) => {
        console.log('Error when load states:', e)
      })
  }

  const handleSelectState = (index, status) => {
    setSelectStates((prev) => {
      if (status) {
        prev.push(index)
      } else {
        prev = prev.filter((item) => item != index)
      }

      const headers = new Headers()

      headers.append('accept', 'application/json')
      headers.append('Content-Type', 'application/json')

      if (process.env.REACT_APP_ENV === 'production') {
        headers.append('telegramid', tg?.initDataUnsafe?.user?.id)
      }

      fetch(
        `${process.env.REACT_APP_HOST}/api/webapp/directions/update?truck_id=${
          truckData.truck.id
        }&states=${prev.join(',')}&all=false`,
        {
          method: 'POST',
          headers: headers
        }
      )
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          console.log(data)
        })
        .catch((e) => {
          console.log('Error when update states:', e)
        })

      return [...prev]
    })
  }

  const handleSelectAllStates = (status) => {
    setSelectStates((prev) => {
      let selectedState = ''

      if (status) {
        setIsSelected(true)

        prev = states.map((item, index) => item.id)

        selectedState = prev.join(',')
      } else {
        setIsSelected(false)
        prev = []
      }

      const headers = new Headers()

      headers.append('accept', 'application/json')
      headers.append('Content-Type', 'application/json')

      if (process.env.REACT_APP_ENV === 'production') {
        headers.append('telegramid', tg?.initDataUnsafe?.user?.id)
      }

      fetch(
        `${process.env.REACT_APP_HOST}/api/webapp/directions/update?truck_id=${
          truckData.truck.id
        }&states=${selectedState}&all=${status ? 'true' : 'false'}`,
        {
          method: 'POST',
          headers: headers
        }
      )
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          console.log(data)
        })
        .catch((e) => {
          console.log('Error when update states:', e)
        })
        .finally(() => {
          setStates(JSON.parse(JSON.stringify(states)))
        })

      return [...prev]
    })
  }

  useEffect(() => {
    handleUpdateLocationWhenZipcodeChanged(zipCode)
  }, [])

  useEffect(() => {
    if (!mapContainer.current || !location) return

    let map = L.map(mapContainer.current, {
      attributionControl: false,
      zoomControl: false,
      zoomAnimation: true
    }).setView(location, 7)

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      updateWhenIdle: true,
      updateWhenZooming: true,
      updateInterval: 200
    }).addTo(map)

    const dIcon = L.icon({
      iconUrl: destination,
      iconSize: [50, 50],
      popupAnchor: [0, -25],
      shadowSize: [68, 55],
      shadowAnchor: [22, 55]
    })

    const marker = L.marker(location, {
      alt: '',
      icon: dIcon
    })
      .addTo(map)
      .bindPopup(
        `<div class="text-sm font-semibold text-black text-center capitalize">${truckData.driver}</div>`
      )
      .openPopup()

    markerRef.current = marker

    const radar = L.circle(marker.getLatLng(), {
      radius: outMiles * 1609.34,
      className: 'zoom',
      stroke: '#55bf3b'
    }).addTo(map)

    radarRef.current = radar

    radar.setStyle({
      color: '#55bf3b'
    })

    // const radiusInMeters = outMiles * 1609.34

    // // Calculate label position (simplified for demonstration)
    // // This is a naive approach and might not work perfectly for all zoom levels or map projections
    // const labelLatLng = L.GeometryUtil.destination(
    //   radarRef.current.getLatLng(),
    //   45,
    //   radiusInMeters,
    //   mapRef.current
    // )

    // const labelContent = `<div style="background-color: ${'#55bf3b'}; padding: 2px 5px; border-radius: 3px; color: #FFFFFF; text-align: center;" >${outMiles} mi</div>`
    // const labelIcon = L.divIcon({
    //   className: 'custom-label', // custom class for CSS styling
    //   html: labelContent,
    //   iconSize: [60, 20]
    // })

    // labelRef.current = L.marker(labelLatLng, { icon: labelIcon }).addTo(map)

    mapRef.current = map

    const zoom = mapRef.current.getBoundsZoom(radarRef.current.getBounds())

    mapRef.current.flyTo(location, zoom)

    mapRef.current = map

    return () => {
      map.remove()
    }
  }, [mapContainer, location, stateOpen])

  useEffect(() => {
    if (isFirstRender.current.outMiles) {
      isFirstRender.current.outMiles = false
      return
    }

    const update = setTimeout(() => {
      if (radarRef.current && mapRef.current) {
        radarRef.current.setRadius(outMiles * 1609.34)

        // const radiusInMeters = outMiles * 1609.34

        // // Calculate label position (simplified for demonstration)
        // // This is a naive approach and might not work perfectly for all zoom levels or map projections
        // const labelLatLng = L.GeometryUtil.destination(
        //   radarRef.current.getLatLng(),
        //   45,
        //   radiusInMeters,
        //   mapRef.current
        // )

        // labelRef.current.setLatLng(labelLatLng)

        sendDataUpdate(
          {
            truck: {
              id: truckData.truck.id,
              radius: outMiles
            }
          },
          truckData.truck.id
        ).catch((e) => {
          console.log('Error when update radius:', e)
        })
      }
    }, 200)

    return () => clearTimeout(update)
  }, [outMiles])

  // useEffect(() => {
  //   if (stateOpen) {
  //     setTimeout(() => {
  //       if (refSelectedAll.current) {
  //         refSelectedAll.current.scrollIntoView({
  //           behavior: 'smooth',
  //           block: 'start'
  //         })
  //       }
  //     }, 300)
  //   }
  // }, [stateOpen])

  useEffect(() => {
    if (showDatePicker) {
      if (datepickerRef.current) {
        setTimeout(() => {
          datepickerRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest'
          })
        }, 100)
      }
    }
  }, [showDatePicker])

  useEffect(() => {
    handleLoadStates()
  }, [])

  return (
    <>
      <div
        className="flex flex-col flex-1 place-content-start overflow-y-scroll"
        ref={containerRef}
      >
        <div className="top-1 mt-3 flex items-center justify-between px-4">
          <img
            src={arrowLeft}
            className="w-6 h-6 active:opacity-50"
            onClick={() => {
              setSelectedTruckData(null)
              setActivePage(0)
            }}
          />
          <div className="text-lg font-bold text-black capitalize -ml-6">
            Preferences
          </div>
          <div></div>
        </div>

        <div className="px-4 basis-0 shrink-0 -top-5 relative mt-6">
          <div>
            <div className="mt-4">
              {!isEditingZip ? (
                <div className="border-1 border-solid border-border-color p-3 rounded-md flex items-center justify-between">
                  <div className="flex justify-between w-full">
                    <div
                      className="flex items-center flex-1 active:opacity-50"
                      onClick={() => {
                        setIsEditingZip(true)
                      }}
                    >
                      <img src={zipIcon} />
                      {tmpZipCode ? (
                        <div className="text-base text-black font-medium ml-1 whitespace-nowrap">
                          {tmpZipCode}
                        </div>
                      ) : (
                        <div className="text-base text-black font-medium ml-1 whitespace-nowrap text-opacity-40">
                          Enter ZIP Code
                        </div>
                      )}
                    </div>
                    <div
                      className="flex gap-1 active:opacity-50"
                      onClick={() => {
                        oldZipCodeAndDate.current = {
                          zipCode: zipCode,
                          date: date,
                          postOfficeCity: postOfficeCity
                        }

                        onOpenUpdateZip()

                        const tmpDate = new Date()

                        tmpDate.setMinutes(0)

                        setTmpDate(tmpDate)
                      }}
                    >
                      <div className="flex items-center">
                        <img src={union} />
                        <div className="text-base text-black font-medium ml-1 whitespace-nowrap">
                          {formatDate(date)}
                        </div>
                      </div>
                      <div className="flex items-center">
                        <img src={clock} />
                        <div className="text-base text-black font-medium ml-1 whitespace-nowrap">
                          {formatTime(date)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Input
                  classNames={{
                    base: 'h-12',
                    inputWrapper: [
                      'rounded-md',
                      'border-1',
                      'shadow-none',
                      'bg-white',
                      'group-data-[focus=true]:bg-white',
                      'group-data-[hover=true]:bg-white',
                      'py-6',
                      'h-12'
                    ],
                    input: [
                      'font-normal',
                      '!text-base',
                      'text-text-input-color',
                      'py-6',
                      'h-12'
                    ]
                  }}
                  autoFocus={true}
                  type="number"
                  ref={zipInputRef}
                  defaultValue={tmpZipCode}
                  value={tmpZipCode}
                  placeholder="Current Location (ZIP)"
                  onBlur={() => {
                    setIsEditingZip(false)
                  }}
                  onChange={(e) => {
                    setTmpZipCode(e.target.value)

                    clearTimeout(zipRef.current)

                    zipRef.current = setTimeout(() => {
                      if (e.target.value.length === 5) {
                        setErrorZip(null)
                        setIsEditingZip(false)
                        handleUpdateZipData(e.target.value)
                      }
                    }, 500)
                  }}
                />
              )}
            </div>
            {errorZip && (
              <div className="text-red-500 mt-1 text-left">{errorZip}</div>
            )}
          </div>
          <div className="grid grid-cols-2 gap-3 mt-3">
            <div
              className="border-1 border-solid border-border-color p-3 rounded-md flex items-center justify-between active:opacity-50"
              onClick={() => {
                setTotalMilesOpen(!totalMilesOpen)
                setStateOpen(false)
              }}
            >
              <div className="text-sm font-medium text-black">Total Miles</div>
              {totalMilesOpen ? <img src={arrowDown} /> : <img src={arrowUp} />}
            </div>
            <div
              className="border-1 border-solid border-border-color p-3 rounded-md flex items-center justify-between active:opacity-50"
              onClick={() => {
                setStateOpen(!stateOpen)
                setTotalMilesOpen(false)
              }}
            >
              <div className="text-sm font-medium text-black">
                Select States(s)
              </div>
              {stateOpen ? <img src={arrowDown} /> : <img src={arrowUp} />}
            </div>
          </div>
          {totalMilesOpen && (
            <div className="grid grid-cols-2 gap-4 mt-3">
              <Input
                classNames={{
                  base: 'h-12',
                  inputWrapper: [
                    'rounded-md',
                    'border-1',
                    'shadow-none',
                    'bg-white',
                    'group-data-[focus=true]:bg-white',
                    'group-data-[hover=true]:bg-white',
                    'py-6',
                    'h-12'
                  ],
                  input: [
                    'font-medium',
                    '!text-base',
                    'text-text-input-color',
                    'py-6',
                    'h-12'
                  ]
                }}
                onChange={(e) => {
                  clearTimeout(minRef.current)

                  minRef.current = setTimeout(() => {
                    sendDataUpdate(
                      {
                        truck: {
                          id: truckData.truck.id,
                          distance_min: parseInt(e.target.value) || 0
                        }
                      },
                      truckData.truck.id
                    ).catch((e) => {
                      console.log('Error when update distance min:', e)
                    })
                  }, 500)
                }}
                defaultValue={truckData?.truck?.distance_min}
                startContent={
                  <div className="text-sm text-placeholder font-medium">
                    Min
                  </div>
                }
                endContent={
                  <div className="font-medium !text-base text-text-input-color">
                    mi
                  </div>
                }
              />
              <Input
                classNames={{
                  base: 'h-12',
                  inputWrapper: [
                    'rounded-md',
                    'border-1',
                    'shadow-none',
                    'bg-white',
                    'group-data-[focus=true]:bg-white',
                    'group-data-[hover=true]:bg-white',
                    'py-6',
                    'h-12'
                  ],
                  input: [
                    'font-medium',
                    '!text-base',
                    'text-text-input-color',
                    'py-6',
                    'h-12'
                  ]
                }}
                onChange={(e) => {
                  clearTimeout(maxRef.current)

                  maxRef.current = setTimeout(() => {
                    sendDataUpdate(
                      {
                        truck: {
                          id: truckData.truck.id,
                          distance_max: parseInt(e.target.value) || 0
                        }
                      },
                      truckData.truck.id
                    ).catch((e) => {
                      console.log('Error when update distance max:', e)
                    })
                  }, 500)
                }}
                defaultValue={truckData?.truck?.distance_max}
                startContent={
                  <div className="text-sm text-placeholder font-medium">
                    Max
                  </div>
                }
                endContent={
                  <div className="font-medium !text-base text-text-input-color">
                    mi
                  </div>
                }
              />
            </div>
          )}
        </div>

        {stateOpen && (
          <div
            className="grid grid-cols-1 gap-3 flex-1 overflow-y-scroll px-4 py-4"
            style={{
              backgroundColor: 'rgba(241, 241, 241, 0.5)'
            }}
          >
            <div
              className="flex items-center justify-between pb-5 border-b-1 border-border-color gap-3"
              key={Math.random()}
              ref={refSelectedAll}
            >
              <div className="flex items-center justify-center gap-2">
                <div className="text-base font-medium text-black">
                  {'Select All'}
                </div>
                {/* <div
                            className={`bg-superbusy bg-opacity-25 text-superbusy text-sm font-bold px-3 py-1 rounded flex-grow-0 min-w-[101px]`}
                          >
                            Super Busy
                          </div> */}
              </div>
              <div>
                <Switch
                  defaultSelected={isSelected}
                  color="primary"
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleSelectAllStates(true)
                    } else {
                      onOpenConfirm()
                    }
                  }}
                />
              </div>
            </div>
            {states.map((item, index) => {
              return (
                <div
                  className="flex items-center justify-between pb-5 border-b-1 border-border-color gap-3"
                  key={item.id + Math.random()}
                >
                  <div className="flex items-center justify-center gap-2">
                    <div className="text-base font-medium text-black">
                      {item.name}
                    </div>
                    {/* <div
                            className={`bg-superbusy bg-opacity-25 text-superbusy text-sm font-bold px-3 py-1 rounded flex-grow-0 min-w-[101px]`}
                          >
                            Super Busy
                          </div> */}
                  </div>
                  <div>
                    <Switch
                      defaultSelected={selectStates.includes(item.id)}
                      color="primary"
                      onChange={(e) => {
                        handleSelectState(item.id, e.target.checked)
                      }}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        )}
        {!stateOpen && (
          <>
            <div className="px-4 mt-8 flex flex-row gap-1">
              <div className="text-xs text-black font-medium">{50}</div>
              <Slider
                size="sm"
                classNames={{
                  base: 'w-full',
                  inputWrapper: 'bg-white shadow-none',
                  thumb: 'bg-primary'
                }}
                defaultValue={outMiles}
                onChange={setOutMiles}
                minValue={50}
                maxValue={500}
                renderThumb={(props) => (
                  <div
                    {...props}
                    className={`${props.className} bg-primary after:bg-primary relative`}
                    data-slot="thumb"
                    data-focused="true"
                    orientation="horizontal"
                    index="0"
                  >
                    <div
                      className="absolute -top-10 bg-primary top text-sm p-1 text-white rounded-lg text-center font-bold whitespace-nowrap"
                      style={{
                        left:
                          outMiles < 100 ? -10 : outMiles > 450 ? -50 : 'unset'
                      }}
                    >
                      Out: {outMiles}mi
                      <div
                        className={`absolute -bottom-2 transform -translate-x-1/2 w-0 h-0 border-t-8 border-t-primary border-l-8 border-transparent border-r-8`}
                        style={{
                          left:
                            outMiles < 100 ? 20 : outMiles > 450 ? 60 : '50%'
                        }}
                      ></div>
                    </div>
                  </div>
                )}
              />
              <div className="text-xs text-black font-medium">{500}</div>
            </div>

            <div className="w-full flex-1 flex-shrink-0 mt-3">
              <div
                className="w-full h-full"
                ref={(el) => (mapContainer.current = el)}
                onTouchEnd={() => {
                  setTimeout(() => {
                    try {
                      mapRef.current && mapRef.current.invalidateSize(true)
                    } catch (e) {}
                  }, 100)
                }}
              >
                <img
                  src={detail}
                  className="h-11 w-11 mt-1/2 absolute right-0 z-[999] active:opacity-50"
                  style={{
                    top: 'calc(50% - 20px)'
                  }}
                  onClick={() => {
                    setSelectedTruckData(JSON.parse(JSON.stringify(truckData)))
                    setActivePage(2)
                  }}
                />

                {loadingZip && (
                  <div className="w-full h-full z-[999] absolute flex justify-center items-center bg-black bg-opacity-50 bg-opacity-50 flex-col">
                    <Spinner className="" size="lg" color="white" />
                    <div
                      className="mt-2 font-bold text-white text-base"
                      style={{
                        fontFamily: 'Manrope'
                      }}
                    >
                      Data loading, please wait..
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <Modal
        isOpen={isOpenConfirm}
        onOpenChange={onOpenChangeConfirm}
        placement="center"
        classNames={{
          wrapper: 'px-5 z-[1001]',
          closeButton: 'mt-2',
          base: 'rounded-md',
          backdrop: 'z-[1001]'
        }}
        closeButton={<></>}
        scrollBehavior={'inside'}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalBody className="text-center border-b-1">
                <div className="pt-9 pb-4">
                  <div className="text-lg">
                    Are you sure you want to deselect all states?
                  </div>
                  <div className="text-lg mt-2">Please confirm to proceed.</div>
                </div>
              </ModalBody>
              <ModalFooter className="justify-center">
                <div
                  className="flex-[0.5] text-center active:opacity-50"
                  onClick={() => {
                    setIsSelected(true)
                  }}
                >
                  <div
                    className="text-base font-semibold text-unactive"
                    onClick={() => {
                      onClose()
                    }}
                  >
                    Cancel
                  </div>
                </div>
                <div
                  className="flex-[0.5] text-center active:opacity-50"
                  onClick={() => {
                    handleSelectAllStates(false)

                    onClose()
                  }}
                >
                  <div className="text-base font-semibold text-superbusy">
                    Disable
                  </div>
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpenUpdateZipConfirm}
        onOpenChange={onOpenUpdateZipConfirm}
        placement="center"
        classNames={{
          wrapper: 'px-5 z-[1001]',
          closeButton: 'mt-2',
          base: 'rounded-md',
          backdrop: 'z-[1001]'
        }}
        closeButton={<></>}
        scrollBehavior={'inside'}
      >
        <ModalContent>
          {() => (
            <>
              <ModalBody className="text-center border-b-1">
                <div className="pt-9 pb-4">
                  <div className="text-lg text-black font-bold">
                    Please confirm the update
                  </div>
                  <div className="text-base mt-2">
                    Updating the zip code will charge $
                    {process.env.REACT_APP_COST} to your account
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="justify-center">
                <div
                  className="flex-[0.5] text-center active:opacity-50"
                  onClick={() => {
                    setIsSelected(true)
                  }}
                >
                  <div
                    className="text-base font-semibold text-unactive"
                    onClick={() => {
                      onCloseUpdateZipConfirm()
                    }}
                  >
                    Cancel
                  </div>
                </div>
                <div
                  className="flex-[0.5] text-center active:opacity-50"
                  onClick={() => {
                    if (zipCode?.length === 5) {
                      handleUpdateLocationWhenZipcodeChanged(zipCode)
                    }

                    onCloseUpdateZipConfirm()
                  }}
                >
                  <div className="text-base font-semibold text-primary">
                    Update
                  </div>
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      {/* <Modal
        isOpen={isOpenUpdateZip}
        onOpenChange={onOpenUpdateZip}
        placement="center"
        classNames={{
          wrapper: 'px-5 z-[1001]',
          closeButton: 'mt-2',
          base: 'rounded-md min-h-[320px] mb-[50%]',
          backdrop: 'z-[1001]'
        }}
        scrollBehavior={'inside'}
        onClose={() => {
          setZipCode(oldZipCodeAndDate.current.zipCode)
          setDate(oldZipCodeAndDate.current.date)
          onCloseUpdateZip()
          setStep(0)
        }}
      >
        <ModalContent>
          {() => (
            <>
              {step == 1 && (
                <ModalHeader className="flex flex-col gap-1">
                  <img
                    src={arrowLeft}
                    className="w-7 h-7 active:opacity-50"
                    onClick={() => {
                      setStep(0)
                    }}
                  />
                </ModalHeader>
              )}
              <ModalBody className="text-center border-b-1 min-h-[]">
                <div className="pt-12 pb-4">
                  {openFrom === 0 ? (
                    <>
                      {step == 1 && (
                        <div className="font-bold text-base text-black mb-2">
                          {`Updated ZIP: ${zipCode} - ${postOfficeCity}`}
                        </div>
                      )}
                      <div className="text-lg text-black font-normal">
                        {step == 0
                          ? 'Please update the ZIP code or confirm the current selection'
                          : 'Please update the date or confirm the current selection'}
                      </div>
                    </>
                  ) : (
                    <>
                      {step == 1 && (
                        <div className="font-bold text-base text-black mb-2">
                          {`Updated Date: ${formatDate(date)}, ${formatTime(
                            date
                          )}`}
                        </div>
                      )}
                      <div className="text-lg text-black font-normal">
                        {step == 0
                          ? ' Please update the Date or confirm the current selection'
                          : 'Please update the ZIP or confirm the current selection'}
                      </div>
                    </>
                  )}
                  <div className="flex flex-col gap-4">
                    {openFrom === 0 ? (
                      <>
                        {step == 0 && (
                          <div>
                            <Input
                              className="mt-11"
                              classNames={{
                                base: 'h-12',
                                inputWrapper: [
                                  'rounded-md',
                                  'border-1',
                                  'shadow-none',
                                  'bg-white',
                                  'group-data-[focus=true]:bg-white',
                                  'group-data-[hover=true]:bg-white',
                                  'py-6',
                                  'h-12'
                                ],
                                input: [
                                  'font-normal',
                                  '!text-base',
                                  'text-black',
                                  'py-6',
                                  'h-12',
                                  'text-center'
                                ]
                              }}
                              onClick={onOpenUpdateZip}
                              startContent={
                                <div className="font-normal text-black text-base">
                                  ZIP:
                                </div>
                              }
                              autoFocus={true}
                              type="number"
                              endContent={
                                <div className="font-normal text-black text-base whitespace-nowrap">
                                  {postOfficeCity}
                                </div>
                              }
                              errorMessage="Please enter a valid ZIP code"
                              validate={!!postOfficeCity}
                              value={zipCode}
                              placeholder="Current Location (ZIP)"
                              onChange={(e) => {
                                setZipCode(e.target.value + '')
                                setPostOfficeCity('')
                              }}
                            />
                            {!postOfficeCity && (
                              <div className="text-red-500 mt-1 text-left">
                                {'Please enter valid ZIP code'}
                              </div>
                            )}
                          </div>
                        )}

                        {step == 1 && (
                          <div className="flex justify-center mt-4">
                            <div className="" ref={datepickerRef}>
                              <DatePicker
                                selected={date}
                                onChange={(currentDate) => {
                                  setDate(currentDate)
                                }}
                                selectsDisabledDaysInRange
                                inline
                                showTimeSelect
                                timeFormat="HH:mm"
                                calendarClassName="w-[calc(100vw-92px)]"
                              >
                                <div>Time Zone: {timeZone}</div>
                              </DatePicker>
                              {date < new Date() && (
                                <div className="text-red-500 mt-1 text-left">
                                  {'Cannot select dates in the past'}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {step == 1 && (
                          <div>
                            <Input
                              className="mt-11"
                              classNames={{
                                base: 'h-12',
                                inputWrapper: [
                                  'rounded-md',
                                  'border-1',
                                  'shadow-none',
                                  'bg-white',
                                  'group-data-[focus=true]:bg-white',
                                  'group-data-[hover=true]:bg-white',
                                  'py-6',
                                  'h-12'
                                ],
                                input: [
                                  'font-normal',
                                  '!text-base',
                                  'text-black',
                                  'py-6',
                                  'h-12',
                                  'text-center'
                                ]
                              }}
                              onClick={onOpenUpdateZip}
                              startContent={
                                <div className="font-normal text-black text-base">
                                  ZIP:
                                </div>
                              }
                              autoFocus={true}
                              type="number"
                              endContent={
                                <div className="font-normal text-black text-base whitespace-nowrap">
                                  {postOfficeCity}
                                </div>
                              }
                              errorMessage="Please enter a valid ZIP code"
                              validate={!!postOfficeCity}
                              value={zipCode}
                              placeholder="Current Location (ZIP)"
                              onChange={(e) => {
                                setZipCode(e.target.value + '')
                                setPostOfficeCity('')
                              }}
                            />
                            {!postOfficeCity && (
                              <div className="text-red-500 mt-1 text-left">
                                {'Please enter valid ZIP code'}
                              </div>
                            )}
                          </div>
                        )}

                        {step == 0 && (
                          <div className="flex justify-center mt-4">
                            <div className="" ref={datepickerRef}>
                              <DatePicker
                                selected={date}
                                onChange={(currentDate) => {
                                  setDate(currentDate)
                                }}
                                selectsDisabledDaysInRange
                                inline
                                showTimeSelect
                                timeFormat="HH:mm"
                                calendarClassName="w-[calc(100vw-92px)]"
                              >
                                <div>Time Zone: {timeZone}</div>
                              </DatePicker>
                              {date < new Date() && (
                                <div className="text-red-500 mt-1 text-left">
                                  {'Cannot select dates in the past'}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="justify-center">
                <Button
                  color="primary"
                  className={cn(
                    'mt-3 w-full rounded-md h-12',
                    (
                      openFrom == 0
                        ? step == 0
                          ? !postOfficeCity
                          : !date || !(date > new Date())
                        : step == 0
                        ? !date || !(date > new Date())
                        : !postOfficeCity
                    )
                      ? 'opacity-50'
                      : 'opacity-100'
                  )}
                  disabled={
                    openFrom == 0
                      ? step == 0
                        ? !postOfficeCity
                        : !date || !(date > new Date())
                      : step == 0
                      ? !date || !(date > new Date())
                      : !postOfficeCity
                  }
                  onClick={() => {
                    if (step == 0) {
                      setStep(1)
                    } else if (step == 1) {
                      sendDataUpdate(
                        {
                          zip: zipCode,
                          loc_from: parserDateToString(new Date(date))
                        },
                        truckData.truck.id
                      )
                        .then((res) => {
                          oldZipCodeAndDate.current = {
                            zipCode: zipCode,
                            date: date
                          }
                        })
                        .catch((e) => {
                          alert('Error when update date:' + e)
                        })

                      onCloseUpdateZip()
                      setStep(0)
                    }
                  }}
                >
                  <div className="text-white text-base font-bold">
                    {step == 0 ? 'Next' : 'Confirm'}
                  </div>
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal> */}
      <Modal
        isOpen={isOpenUpdateZip}
        onOpenChange={onOpenUpdateZip}
        placement="center"
        classNames={{
          wrapper: 'px-5 z-[1001]',
          closeButton: 'mt-2',
          base: 'rounded-md min-h-[320px] mb-[50%]',
          backdrop: 'z-[1001]'
        }}
        scrollBehavior={'inside'}
        onClose={() => {
          onCloseUpdateZip()
          setZipCode(oldZipCodeAndDate.current.zipCode)
          setDate(oldZipCodeAndDate.current.date)
          setPostOfficeCity(oldZipCodeAndDate.current.postOfficeCity)
        }}
      >
        <ModalContent>
          {() => (
            <>
              {step == 1 && (
                <ModalHeader className="flex flex-col gap-1">
                  <img
                    src={arrowLeft}
                    className="w-7 h-7 active:opacity-50"
                    onClick={() => {
                      setStep(0)
                    }}
                  />
                </ModalHeader>
              )}
              <ModalBody className="text-center border-b-1 min-h-[]">
                <div className="pt-12 pb-4">
                  <div className="font-bold text-base text-black mb-2">
                    {`Updated ZIP: ${zipCode} - ${postOfficeCity || ''}`}
                  </div>
                  <div className="text-lg text-black font-normal">
                    Please select the date or confirm the current selection
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="flex justify-center mt-4">
                      <div className="" ref={datepickerRef}>
                        <DatePicker
                          selected={tmpDate}
                          onChange={(currentDate) => {
                            setTmpDate(currentDate)
                          }}
                          selectsDisabledDaysInRange
                          inline
                          showTimeSelect
                          timeIntervals={60}
                          timeFormat="HH aa"
                          calendarClassName="w-[calc(100vw-92px)]"
                        >
                          <div>Time Zone: {timeZone}</div>
                        </DatePicker>
                        {new Date(tmpDate.toDateString()) <
                          new Date(new Date().toDateString()) && (
                          <div className="text-red-500 mt-1 text-left">
                            {'Cannot select dates in the past'}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="justify-center">
                <Button
                  color="primary"
                  className={cn(
                    'mt-3 w-full rounded-md h-12',
                    !tmpDate ||
                      new Date(tmpDate.toDateString()) <
                        new Date(new Date().toDateString()) ||
                      !zipCode
                      ? 'opacity-50'
                      : 'opacity-100'
                  )}
                  disabled={
                    !tmpDate ||
                    new Date(tmpDate.toDateString()) <
                      new Date(new Date().toDateString()) ||
                    !zipCode
                  }
                  onClick={() => {
                    setLoadingZip(true)

                    sendDataUpdate(
                      {
                        zip: zipCode,
                        loc_from: parserDateToString(new Date(tmpDate))
                      },
                      truckData.truck.id
                    )
                      .then((res) => res.json())
                      .then((res) => {
                        setTmpZipCode(res.zip)

                        setDate(tmpDate)

                        setTmpDate(new Date())

                        oldZipCodeAndDate.current = {
                          zipCode: res.zip,
                          date: date
                        }

                        handleUpdateLocationWhenZipcodeChanged(res.zip)

                        setLoadingZip(false)
                      })
                      .catch((e) => {
                        if (!navigator.onLine) {
                          alert('No server connection. Check internet.')
                        } else {
                          alert('Error when update date: ' + e)
                        }
                        setLoadingZip(false)
                      })

                    onCloseUpdateZip()
                  }}
                >
                  <div className="text-white text-base font-bold">Confirm</div>
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpenInputZip}
        onOpenChange={onOpenInputZip}
        placement="center"
        classNames={{
          wrapper: 'px-5 z-[1001]',
          closeButton: 'mt-2',
          base: 'rounded-md min-h-[320px] mb-[50%]',
          backdrop: 'z-[1001]'
        }}
        scrollBehavior={'inside'}
        onClose={() => {
          onCloseUpdateZip()
          setZipCode(oldZipCodeAndDate.current.zipCode)
          setDate(oldZipCodeAndDate.current.date)
          setPostOfficeCity(oldZipCodeAndDate.current.postOfficeCity)
        }}
      >
        <ModalContent>
          {() => (
            <>
              {step == 1 && (
                <ModalHeader className="flex flex-col gap-1">
                  <img
                    src={arrowLeft}
                    className="w-7 h-7 active:opacity-50"
                    onClick={() => {
                      setStep(0)
                    }}
                  />
                </ModalHeader>
              )}
              <ModalBody className="text-center border-b-1 min-h-[]">
                <div className="pt-12 pb-4">
                  <div className="font-bold text-base text-black mb-2">
                    {`Updated ZIP: ${zipCode} - ${postOfficeCity || ''}`}
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="flex justify-center mt-4">
                      <div className="" ref={datepickerRef}>
                        <DatePicker
                          selected={new Date()}
                          onChange={(currentDate) => {
                            setDate(currentDate)
                          }}
                          selectsDisabledDaysInRange
                          inline
                          showTimeSelect
                          timeFormat="HH:mm"
                          calendarClassName="w-[calc(100vw-92px)]"
                        >
                          <div>Time Zone: {timeZone}</div>
                        </DatePicker>
                        {date < new Date() && (
                          <div className="text-red-500 mt-1 text-left">
                            {'Cannot select dates in the past'}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="justify-center">
                <Button
                  color="primary"
                  className={cn(
                    'mt-3 w-full rounded-md h-12',
                    !date || !(date > new Date()) || !zipCode
                      ? 'opacity-50'
                      : 'opacity-100'
                  )}
                  disabled={!date || !(date > new Date()) || !zipCode}
                  onClick={() => {
                    setLoadingZip(true)

                    handleUpdateZipData(tmpZipCode)

                    onCloseUpdateZip()
                  }}
                >
                  <div className="text-white text-base font-bold">Select</div>
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
